import React from "react"
import tw from "twin.macro"
import { graphql } from "gatsby"

// Components
import Layout from "@components/layout"
import Seo from "@components/seo"
import StaticHero from "@components/staticHero"
import Container from "@components/container"
import PrimaryCta from "@components/PrimaryCta"
import { heading1 } from "@styles/ui"
import BgImageSection from "@components/narcolepsy/BgImageSection"
import PatientVoice from "@components/PatientVoice"
import Section from "@components/section"
import FindADoctorWidget from "@components/FindADoctor"

const FindAPhysicianPage = ({ location, data }) => {
  return (
    <Layout location={location} exitModalColour="orange">
      <Seo
        title="Find a Narcolepsy Physician Near You | XYWAV®"
        description="Locate a physician near you that is knowledgeable about the narcoleptic condition. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse."
        location={location}
      />
      {/* Static hero */}
      <StaticHero eyebrow="FIND A DOCTOR" gradient="purple-red">
        Connect with a healthcare provider
      </StaticHero>

      <BgImageSection
        imageData={data}
        isWhiteBg
        widerImage
        mobileAspectRatio={"90%"}
        altText="A man discussing his narcolepsy symptoms with a doctor"
        containerStyles={tw`2xl:(max-h-[26rem])`}
        imageStyles={tw`2xl:(max-h-[26rem])`}
      >
        <>
          <div tw="before:(gradientRed block relative w-[124px] mb-[15px] h-1.5 rounded-md) xl:before:(mb-6)"></div>
          <h2
            css={[
              heading1,
              tw`text-[22px] leading-[28px] text-merlin mb-[15px] pb-0 lg:(text-[25px] leading-[30px] mb-[25px])`,
            ]}
          >
            Reasons to talk to a doctor about
            <br tw="hidden md:block" /> XYWAV&nbsp;today
          </h2>
          <p tw="text-base mb-4">
            <span tw="font-black">
              XYWAV is taken at night and thought to work during sleep
            </span>{" "}
            to help improve cataplexy and/or excessive daytime sleepiness (EDS)
            in narcolepsy during the day.*
          </p>
          <p tw="text-base 2xl:min-w-[42.625rem]">
            <span tw="font-black">With only 131 mg of sodium</span> at the
            equivalent 9&#8209;g nightly dose,{" "}
            <span tw="font-black">
              XYWAV contains ~1509&nbsp;mg less sodium per night than all
              high&#8209;sodium oxybates at the equivalent dose
            </span>
            . This means{" "}
            <span tw="font-black">
              choosing XYWAV over high&#8209;sodium oxybates
            </span>{" "}
            can help treat your symptoms of cataplexy and/or EDS in narcolepsy,{" "}
            <span tw="font-black">
              while also reducing your intake over time
            </span>
            .
          </p>
          <p tw="ml-1 mt-5 text-[14px] mb-2 md:(mt-[25px] mb-0)">
            <span tw="relative -ml-1">*</span>The exact way XYWAV works for the
            treatment of cataplexy and EDS in patients with narcolepsy is
            unknown.
          </p>
        </>
      </BgImageSection>

      <FindADoctorWidget location={location} />

      <PatientVoice
        quote={
          <>
            If you think you might have narcolepsy, don&apos;t stop advocating
            for yourself. Keep doing your research and find a team knowledgeable
            about the condition.”
          </>
        }
        patient="Jake"
        diagnosis="Diagnosed with narcolepsy type 1."
        imageData={data}
        id="jake-doctor-quote"
        footnote="Compensated for his time. Results may vary."
        cta={{
          text: "LIVING WITH NARCOLEPSY",
          url: "/narcolepsy/living-with-narcolepsy/",
          mdSmall: true,
        }}
      />

      <Section bgColour={tw`lilac-gradient-vertical`}>
        <Container addedStyles={[tw`text-center`]}>
          <div tw="before:(gradientRed block relative w-[100px] mb-[15px] h-1.5 rounded-md mx-auto) xl:before:(mb-6 w-[135px])"></div>
          <h2
            css={[
              heading1,
              tw`text-[22px] leading-[28px] text-merlin mb-[15px] pb-0 lg:(text-[25px] leading-[30px] mb-[25px])`,
            ]}
          >
            Prepare for your next appointment by reflecting on how your symptoms
            are impacting you.
          </h2>

          <div tw="w-[168px] mx-auto">
            <PrimaryCta
              url={"/narcolepsy/talk-to-your-doctor/#quiz"}
              noInterstitial
              mdSmall
            >
              Get Started
            </PrimaryCta>
          </div>
        </Container>
      </Section>
    </Layout>
  )
}

export default FindAPhysicianPage

export const query = graphql`
  query {
    bgImageSection: file(
      relativePath: { eq: "find-a-physician/man-with-doctor.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 960, height: 600, placeholder: NONE)
      }
    }
    bgImageSectionMobile: file(
      relativePath: { eq: "find-a-physician/man-with-doctor.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 767, placeholder: NONE)
      }
    }
    colImage: file(
      relativePath: { eq: "talk-to-your-doctor/have-questions.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 470, placeholder: NONE)
      }
    }
    patientVoice: file(
      relativePath: { eq: "find-a-physician/jake-headshot.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(height: 425, placeholder: NONE)
      }
    }
    patientVoiceMobile: file(
      relativePath: { eq: "find-a-physician/jake-headshot-mobile.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 767, placeholder: NONE)
      }
    }
  }
`
