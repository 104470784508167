import React, { useEffect } from "react"
import { Script } from "gatsby"
import { FINDADOCTOR_URL_PROD, FINDADOCTOR_URL_STAGE } from "@utils/constants"
import { isProd } from "@components/utils"

// markup
const FindADoctorWidget = ({ location }) => {
  const isNarcolepsy = location.pathname.includes("narcolepsy")

  useEffect(() => {
    if (window.partnerconfig) window.partnerconfig = undefined
  }, [])

  const findADoctorUrl =
    location && isProd(location.hostname)
      ? FINDADOCTOR_URL_PROD
      : FINDADOCTOR_URL_STAGE

  const ddcMutationObserver = () => {
    // Select the node that will be observed for mutations
    const targetNode = document.getElementById("find-a-doctor-widget")
    if (targetNode) {
      const config = {
        subtree: true,
        childList: true,
        attributeFilter: ["href"],
      }

      // Callback function to execute when mutations are observed
      const callback = mutationList => {
        for (const mutation of mutationList) {
          if (mutation.target.classList.contains("ddc-container")) {
            const eventSetupModals = new CustomEvent("setupModals", {})
            document.dispatchEvent(eventSetupModals)
          }
        }
      }

      // Create an observer instance linked to the callback function
      const observer = new MutationObserver(callback)

      // Start observing the target node for configured mutations
      observer.observe(targetNode, config)

      return () => observer.disconnect()
    }
  }

  useEffect(() => {
    ddcMutationObserver()
  }, [])

  return (
    <div id="find-a-doctor-widget">
      <directory-widget
        partner={isNarcolepsy ? 618 : 615} // Narcolepsy is 618, IH is 615
      ></directory-widget>
      <Script
        type="text/javascript"
        id="directory-widget"
        src={findADoctorUrl}
      ></Script>
    </div>
  )
}

export default FindADoctorWidget
